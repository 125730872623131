import React from 'react';
import PropTypes from 'prop-types';
import { BITBOX } from 'bitbox-sdk';
import Nav from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import {
  ContentBlock,
  H1,
  InputLabel,
  Paragraph,
  CheckSolid,
} from '@bitcoin-portal/bitcoincom-storybook';
import { injectIntl, FormattedMessage } from 'react-intl';
import merge from 'lodash/merge';
import {
  Wrapper,
  InputWrapper,
  Red,
  InputError,
  Form,
  CustomContentBlock,
  TextArea,
  StoplightInput,
  CheckWrapper,
  ValidatorBox,
  CustomUL,
  CustomParagraph,
} from './styled';
import SEO from './SEO';

const bitbox = new BITBOX();
const inputState = { untouched: 0, valid: 1, invalid: 2 };

class VerifyMessage extends React.Component {
  constructor(props) {
    super(props);

    this.initialFormData = {
      address: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      message: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      signature: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.verifyMessage = this.verifyMessage.bind(this);

    this.state = {
      formData: merge({}, this.initialFormData),
      sigGood: null,
    };
  }

  validateInput = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;

    if (name === 'address' && value.length) {
      try {
        bitbox.Address.toLegacyAddress(value);
      } catch (err) {
        field.state = inputState.invalid;
        field.error = formatMessage({
          id: 'verify-message.errors.addressInvalid',
        });
        return field;
      }
    }

    if (!value) {
      field.state = inputState.invalid;
      /*
      field.error = formatMessage({
        id: 'verify-message.errors.required',
      }); */
    }

    return field;
  };

  handleInputChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;

    this.setState(
      {
        formData: { ...formData, [name]: this.validateInput({ name, value }) },
      },
      this.verifyMessage,
    );
  }

  // eslint-disable-next-line consistent-return
  verifyMessage() {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData, sigGood } = this.state;

    // If all input values are valid
    if (
      formData.address.state === 1 &&
      formData.message.state === 1 &&
      formData.signature.state === 1
    ) {
      try {
        const signatureValid = bitbox.BitcoinCash.verifyMessage(
          formData.address.value,
          formData.signature.value,
          formData.message.value,
        );
        if (signatureValid) {
          return this.setState({
            sigGood: true,
          });
        }
        return this.setState({
          sigGood: false,
        });
      } catch (err) {
        let errorMsg = err.message;
        if (errorMsg.includes('Invalid signature length')) {
          errorMsg = formatMessage({
            id: 'verify-message.errors.sigInvalidLength',
          });
          this.setState({
            sigGood: false,
            formData: {
              ...formData,
              signature: {
                error: errorMsg,
                state: 2,
                value: formData.signature.value,
              },
            },
          });
        }
      }
      // If the user is changing from valid input and that makes it false, show invalid
      // Otherwise though, don't show anything, because the user is still completing the form
    } else if (sigGood !== null) {
      return this.setState({
        sigGood: false,
      });
    }

    // if all 3 have valid values, check the sig
    /*
    bitbox.BitcoinCash.verifyMessage(
        bitcoincash:qp2zvw3zpk5xx43w4tve7mtekd9kaxwj4uenq9eupv'
        IIYVhlo2Z6TWFjYX1+YM+7vQKz0m+zYdSe4eYpFLuAQDEZXqll7lZC8Au22VI2LLP5x+IerZckVk3QQPsA3e8/8=
        EARTH
      );
    */
  }

  render() {
    const { formData, sigGood } = this.state;
    const {
      locale,
      intl: { formatMessage },
    } = this.props;
    return (
      <div>
        <SEO />
        <Wrapper>
          <Nav />
          <ContentBlock hero>
            <H1>
              <FormattedMessage id="verify-message.header.title" />
            </H1>
            <Paragraph center>
              <FormattedMessage id="verify-message.header.text" />
            </Paragraph>
          </ContentBlock>
          <CustomContentBlock hero noPadding>
            <Form>
              <InputWrapper show>
                <InputLabel>
                  <FormattedMessage id="verify-message.labels.address" />{' '}
                  <Red>*</Red>
                  <ValidatorBox show={sigGood}>
                    <CustomUL>
                      <li>
                        <CheckWrapper>
                          <CheckSolid />
                        </CheckWrapper>
                        <CustomParagraph>
                          <FormattedMessage id="verify-message.valid.addressValid" />
                        </CustomParagraph>
                      </li>
                    </CustomUL>
                  </ValidatorBox>
                </InputLabel>

                <StoplightInput
                  sigGood={sigGood}
                  sigBad={sigGood === false}
                  id="inputAddress"
                  name="address"
                  type="string"
                  value={formData.address.value}
                  onChange={this.handleInputChange}
                  placeholder={formatMessage({
                    id: 'verify-message.input.address',
                  })}
                  required
                />
              </InputWrapper>
              <InputError>{formData.address.error}</InputError>
              <InputWrapper show>
                <InputLabel>
                  <FormattedMessage id="verify-message.labels.message" />{' '}
                  <Red>*</Red>
                  <ValidatorBox show={sigGood}>
                    <CustomUL>
                      <li>
                        <CheckWrapper>
                          <CheckSolid />
                        </CheckWrapper>
                        <CustomParagraph>
                          <FormattedMessage
                            id="verify-message.valid.msgValid"
                            values={{
                              address: formData.address.value,
                            }}
                          />
                        </CustomParagraph>
                      </li>
                    </CustomUL>
                  </ValidatorBox>
                </InputLabel>
                <TextArea
                  sigGood={sigGood}
                  sigBad={sigGood === false}
                  rows="10"
                  id="inputMessage"
                  name="message"
                  type="string"
                  value={formData.message.value}
                  onChange={this.handleInputChange}
                  placeholder={formatMessage({
                    id: 'verify-message.input.message',
                  })}
                  required
                />
              </InputWrapper>
              <InputError>{formData.message.error}</InputError>
              <InputWrapper show>
                <InputLabel>
                  <FormattedMessage id="verify-message.labels.signature" />{' '}
                  <Red>*</Red>
                  <ValidatorBox show={sigGood}>
                    <CustomUL>
                      <li>
                        <CheckWrapper>
                          <CheckSolid />
                        </CheckWrapper>
                        <CustomParagraph>
                          <FormattedMessage id="verify-message.valid.sigValid" />
                        </CustomParagraph>
                      </li>
                    </CustomUL>
                  </ValidatorBox>
                </InputLabel>
                <StoplightInput
                  sigGood={sigGood}
                  sigBad={sigGood === false}
                  id="inputSignature"
                  name="signature"
                  type="string"
                  value={formData.signature.value}
                  onChange={this.handleInputChange}
                  placeholder={formatMessage({
                    id: 'verify-message.input.signature',
                  })}
                  required
                />
              </InputWrapper>
              <InputError>{formData.signature.error}</InputError>
            </Form>
          </CustomContentBlock>

          <Footer />
        </Wrapper>
      </div>
    );
  }
}

VerifyMessage.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(VerifyMessage);
