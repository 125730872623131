import styled, { css } from 'styled-components';
import {
  theme,
  media,
  ContentBlock,
  Input,
  UL,
  Paragraph,
} from '@bitcoin-portal/bitcoincom-storybook';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  & > input {
    width: 100%;
    margin-top: ${theme.spacing.unit * 2}px;
  }

  ${media.sm`
    & > input {
      max-width: 450px;
      margin-top: ${theme.spacing.unit * 4}px;
    }
  `};
`;

export const Address = styled.div`
  margin-bottom: ${theme.spacing.unit * 2}px;
  word-break: break-all;
`;

export const Content = styled.div`
  max-width: 600px;
  padding-top: ${theme.spacing.unit * 2}px;

  ${media.sm`
    padding-top: ${theme.spacing.unit * 4}px;
  `};

  & > div:first-child {
    margin-bottom: ${theme.spacing.unit * 2}px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing.unit * 8}px;

  & > section {
    padding: 0;
  }

  & > section > div {
    min-height: unset;
  }

  & > section > div > div {
    padding: 0;
    margin: 0;
  }

  & > section > div > div > input {
    width: 100%;
  }

  ${media.sm`
    margin-bottom: ${theme.spacing.unit * 16}px;
  `};
`;

export const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: ${theme.spacing.unit * 3}px;
  padding-top: ${theme.spacing.unit}px;
  display: ${({ show = false }) => (show === true ? 'block' : 'none')};

  & > input {
    width: 100%;
    max-width: unset;
    margin: 0;
  }

  @media screen and (min-width: ${theme.breakpoints.md}px) {
    width: 100%;
    padding-top: ${theme.spacing.unit * 2}px;
  }
`;

export const Red = styled.span`
  color: #f44336;
`;

export const InputError = styled.div`
  color: #f44336;
  font-size: 0.8rem;
`;

export const Form = styled.div`
  text-align: left;
  padding-bottom: ${theme.spacing.unit * 3}px;
  @media screen and (min-width: ${theme.breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const CustomContentBlock = styled(ContentBlock)`
  padding-left: 0;
  overflow-wrap: break-word;
  padding-right: 0;
  transition: opacity ${theme.transitions.duration.default}ms
    ${theme.transitions.easing.easeInOut};

  ${({ disabled = false }) => {
    if (disabled === false) return '';
    return css`
      opacity: 0.3;
      pointer-events: none;
    `;
  }};

  ${({ noPadding = false }) => {
    if (noPadding === false) return '';
    return css`
      padding: 0;

      & > div > div {
        padding: 0;
      }
    `;
  }};
`;

export const TextArea = styled.textarea`
  width: 100%;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  background-color: ${({ sigGood = false }) =>
    sigGood === true ? 'rgb(10, 193, 142, 0.4)' : 'none'};
  background-color: ${({ sigBad = false }) =>
    sigBad === true ? 'rgb(255,0,0,.5)' : 'none'};
  transition: ${({ sigGood = false }) =>
    sigGood === true ? 'background-color 0.5s ease-in' : 'none'};
  border: none;
  outline: none;
  margin: 0;
  box-sizing: border-box;
  border-radius: ${theme.border.radius.default}px;
  padding: ${theme.spacing.unit * 3}px;
  border: ${theme.border.solid.default} ${theme.palette.border.default};
  color: ${theme.palette.text.primary};
  margin: ${theme.spacing.unit}px 0;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ${({ padding = false }) =>
    padding &&
    css`
      padding-right: ${theme.spacing.unit * 7}px;
    `};
  &::placeholder {
    color: ${theme.palette.text.placeholder};
  }
  &:focus,
  &:focus-within {
    border-color: ${theme.palette.primary.main};
  }
  ${({ disabled = false }) => {
    if (disabled === false) return '';
    return css`
      background-color: ${theme.palette.background.headerLight};
      border-color: ${theme.palette.text.disabled};
      color: ${theme.palette.text.disabled};
      pointer-events: none;
      &:focus,
      &:focus-within {
        border-color: ${theme.palette.text.disabled};
      }
    `;
  }};
`;

export const StoplightInput = styled(Input)`
  background-color: ${({ sigGood = false }) =>
    sigGood === true ? 'rgb(10, 193, 142, 0.4)' : 'none'};
  background-color: ${({ sigBad = false }) =>
    sigBad === true ? 'rgb(255,0,0,.5)' : 'none'};
  transition: ${({ sigGood = false }) =>
    sigGood === true ? 'background-color 0.5s ease-in' : 'none'};
`;

export const CheckWrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  ${theme.palette.p3green.background};
  color: ${theme.palette.primary.contrastText};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing.unit * 2}px;

  & > svg {
    width: 12px;
  }
`;

export const ValidatorBox = styled.div`
  visibility: ${({ show = false }) => (show === true ? 'visible' : 'hidden')};
  opacity: ${({ show = false }) => (show === true ? 1 : 0)};
  display: inline-block;
  padding-left: 12px;
  transition: visibility, opacity 2s ease-in;
`;

export const CustomUL = styled(UL)`
  width: 100%;
  margin: 0 !important;

  & > li:first-child {
    margin: 0;
  }

  ${media.md`
    margin-top: 0;

  `};
`;

export const CustomParagraph = styled(Paragraph)`
  padding-bottom: 0px;
  margin: 0 !important;
  color: rgb(10, 193, 142);
`;
